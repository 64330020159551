/**
 * AllOfUs Public API
 * The API for the AllOfUs data browser and public storefront.
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Analysis } from './analysis';


export interface ConceptAnalysis { 
    /**
     * concept id
     */
    conceptId: string;
    countAnalysis?: Analysis;
    genderAnalysis?: Analysis;
    ageAnalysis?: Analysis;
    /**
     * measurement value gender analysis
     */
    measurementValueGenderAnalysis?: Array<Analysis>;
    /**
     * age analysis for measurement values
     */
    measurementValueAgeAnalysis?: Array<Analysis>;
    /**
     * measurement distribution analysis
     */
    measurementDistributionAnalysis?: Array<Analysis>;
    /**
     * measurement gender count by unit analysis
     */
    measurementGenderCountAnalysis?: Array<Analysis>;
    ehrCountAnalysis?: Analysis;
    participantCountAnalysis?: Analysis;
}

